import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'no-idp-provider',
  styleUrls: ['./no-idp-provider.component.scss'],
  templateUrl: './no-idp-provider.component.html'
})
export class NoIdpProviderComponent {

  @Input() errorMessage;
  @Input() errorCode;

  @Output()
  buttonClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  path: string;

  constructor() {
  }

  ngOnInit() {
    this.path = window.location.origin + window.location.pathname;
  }

  public onButtonClicked(e: MouseEvent): void {
    this.buttonClicked.emit(e);
  }
}
