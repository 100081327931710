<div class="dh-drill-down-container" id  [attr.automation-id]="inputLabel">
  <label class="dh-form-component-header" [ngClass]="{ 'required_input-label': required }" for="{{drillDownId}}-input" *ngIf="inputLabel">
    {{inputLabel | translate}}
  </label>
  <div class="dh-drill-down">
    <input id="{{drillDownId}}-input"
    class="dh-input-field"
    type="text"
    [attr.name]="name"
    ngModel
    [(ngModel)]="inputValue"
    [disabled]="readOnly"
    [required]="required"
    [readOnly]="!allowFreeText"
    (ngModelChange)="onInputChange()" />
    <button mat-icon-button id="{{drillDownId}}-button" type="button" class="btn dh-hoverable drilldown-btn"
            (click)="handleDrillDown()"
            [disabled]="readOnly">
      <app-fn-ui-icon icon="dots_horizontal" size="18px"></app-fn-ui-icon>
    </button>
  </div>
</div>

