<div class="dh-simple-condition-wrapper" [ngClass]="{ 'selected': simpleCondition.isChecked }">

  <button *ngIf="isOperatorChangedOnInit"
          mat-icon-button dense
          class="condition-operator-changed"
          [title]="operatorChangedOnInitAlertText">
    <app-fn-ui-icon icon="report_problem" size="15px"></app-fn-ui-icon>
  </button>

  <div class="dh-simple-condition" [ngClass]="{ 'condition-changed': simpleCondition.conditionChanged }">

    <app-fn-ui-checkbox class="condition-checkbox"
                        azure-id="condition-check"
                        [(value)]="simpleCondition.isChecked">
    </app-fn-ui-checkbox>

    <app-condition-operand [operand]="simpleCondition.leftOperand"
                           [operandDataType]="leftOperandDataType"
                           [isFocus]="leftOperandFocus"
                           [isValid]="isLeftOperandValid"
                           [invalidValueErrorText]="leftOperandInvalidMsg"
                           (operandChanged)="leftOperandChanged($event)"
                           class="left-operand">
    </app-condition-operand>

    <app-condition-operator class="condition-operator-wrapper"
                            [operator]="simpleCondition.conditionOperator"
                            [operatorList]="operatorList"
                            (operatorChanged)="onConditionOperatorChange($event)">
    </app-condition-operator>

    <div class="condition-right-operand-wrapper right-operand" azure-id="right-operand">
      <app-condition-operand *ngIf="rightOperandControls.autocomplete"
                             [operand]="simpleCondition.rightOperand"
                             [operandDataType]="rightOperandDataType"
                             [isRightOperand]="true"
                             [allowedTypes]="rightOperandTypes"
                             [isValid]="isRightOperandValid"
                             [invalidValueErrorText]="rightOperandInvalidMsg"
                             (operandChanged)="rightOperandChanged($event)"
                             >
      </app-condition-operand>

      <input *ngIf="rightOperandControls.input"
             class="dh-input-field"
             type="text"
             [(ngModel)]="simpleCondition.rightOperand.alias"
             [disabled]="isRightOperandInputDisabled"
             required
             (ngModelChange)="rightOperandChanged()">

      <app-fn-ui-drill-down *ngIf="rightOperandControls.drilldown"
                            [dhDrillDown]="drillDownSource"
                            [inputValue]="simpleCondition.rightOperand.alias"
                            [filterParameters]="rightOperandInParamsFilter"
                            [preventMultiSelect]="shouldPreventMultiSelect"
                            [readOnly]="isEditMode"
                            [entity]="'rules'"
                            [required]="true"
                            [keyId]="keyId"
                            [allowFreeText]="drillDownSource === accountProfileId"
                            (rowSelected)="onDrillDownValuesSelected($event.selectedRows)"
                            (inputChanged)="onDrillDownInputChange($event)">
      </app-fn-ui-drill-down>

      <div class="dh-select" *ngIf="rightOperandControls.select">
        <app-fn-ui-select class="selectpicker dh-select"
                          [(ngModel)]="simpleCondition.rightOperand.alias"
                          [options]="rightOperandOptions"
                          [required]="true"
                          (ngModelChange)="rightOperandChanged()">
        </app-fn-ui-select>
      </div>
    </div>

    <div class="dh-simple-condition-actions">
      <button azure-id="add-condition"
              mat-icon-button dense
              class="round-btn add-condition"
              (click)="addCondition()">
        <app-fn-ui-icon icon="plus" ></app-fn-ui-icon>
      </button>

      <ng-container *ngTemplateOutlet="dragHandleTemplate"></ng-container>
    </div>
  </div>

  <app-inner-conditions *ngIf="simpleCondition.innerConditions"
                        [conditions]="simpleCondition.innerConditions"
                        [operandType]="leftOperandDataType"
                        [selectedOperator]="simpleCondition.conditionOperator">
  </app-inner-conditions>

</div>
