<body>
<div class="wrapper">
  <div class="container">
    <div class="col-left">
      <h1>{{ 'Sign In Failed' }}</h1>
      <div>{{ 'No IDP providers found for url ' }} <a [href]="path">{{path }}</a></div>
      <div>{{'Please contact your system administrator' }}</div>
    </div>
    <div class="col-right">
      <img src="assets/images/png/robot.png" alt="Broken robot image">
    </div>
  </div>
</div>
</body>

