import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { AlertsModule } from './alerts/alerts.module';
import { IdleModule } from './idle/idle.module';
import { UserMenuModule } from './user-menu/user-menu.module';
import { FnDynamicMenuModule } from '../../../../../projects/menu/src/lib/dynamic-menu/fn-dynamic-menu.module';
import { TranslationsModule } from './translations/translations.module';
import { KeyboardShortcutsModule } from './keyboard-shortcuts/keyboard-shortcuts.module';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { NavigationModule } from './navigation/navigation.module';
import { NavigationSearchModule } from './navigation-search/navigation-search.module';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/logo/logo.component';
import { HomeService } from './services/home.service';
import { StoreService } from './services/store.service';
import { StateStoreService } from './routing/services/state-store.service';
import { OopsPageComponent } from './components/oops-page/oops-page.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { OfficeSelectorComponent } from './components/office-selector/office-selector.component';
import { FnUiIconModule } from '../shared/fn-ui-icons/fn-ui-icon/fn-ui-icon.module';
import { FormsModule } from '@angular/forms';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FnUiCloseIconModule } from '../shared/fn-ui-icons/fn-ui-close-icon/fn-ui-close-icon.module';
import { MatRadioModule } from '@angular/material/radio';
import { SessionSyncService } from './services/session-sync.service';
import { MultiTabsModule } from './multi-tabs/multi-tabs.module';
import { MenuService } from './services/menu.service';
import { FeatureFlagsService } from './feature-flags/services/feature-flags.service';
import { FnUiButtonModule } from '../shared/fn-ui-button/fn-ui-button.module';
import { PopupService } from './services/popup.service';
import { GPPFrontEndService } from './services/gpp-frontend.service';
import {NoIdpProviderComponent} from "./components/no-idp-provider/no-idp-provider.component";

@NgModule({
  declarations: [
    HeaderComponent,
    LogoComponent,
    OopsPageComponent,
    AutofocusDirective,
    OfficeSelectorComponent,
    NoIdpProviderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    HttpClientModule,
    AlertsModule,
    IdleModule,
    UserMenuModule,
    FnDynamicMenuModule,
    TranslationsModule,
    KeyboardShortcutsModule,
    UserSettingsModule,
    NavigationModule,
    NavigationSearchModule,
    FnUiIconModule,
    FormsModule,
    FnUiDialogModule,
    FnUiCloseIconModule,
    MatRadioModule,
    MultiTabsModule,
    FnUiButtonModule
  ],
  providers: [
    HomeService,
    StoreService,
    StateStoreService,
    SessionSyncService,
    MenuService,
    FeatureFlagsService,
    PopupService,
    GPPFrontEndService
  ],
  exports: [
    AlertsModule,
    UserMenuModule,
    KeyboardShortcutsModule,
    UserSettingsModule,
    NavigationModule,
    NavigationSearchModule,
    HeaderComponent,
    AutofocusDirective,
    FnUiButtonModule
  ]
})
export class CoreModule { }
